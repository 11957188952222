/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from "@core/utils/filter";
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class CardTransactionService {
  getCardTransactionLists(queryParams) {
    const pars = {
      user_id: queryParams.user_id,
      email: queryParams.email,
      username: queryParams.username,
      phone: queryParams.phone,
      ref: queryParams.ref,
      code: queryParams.code,
      serial: queryParams.serial,
      type: queryParams.type,
      status: queryParams.status,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/card-transaction/index", { headers: authHeader(), params: pars })
      .then((response) => {
        return response;
      });
  }
  getCardType() {
    return instance.get("/api/card-type/index", { headers: authHeader() })
    .then((response) => {
      return response;
    });
  }
  getDataChart() {
    return instance.get("/api/dashboard/index", { headers: authHeader() })
    .then((response) => {
      return response;
    });
  }
}

export default new CardTransactionService();